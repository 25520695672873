<template>
    <div>
        <Navbar class="nav-z-idx" />
        <div class="container-v3">
            <!-- <div class="img-background">
        <img src="@/assets/images/Image-organic-shape.svg" alt="" class="img-organic-shape">
        <img src="@/assets/images/Image-leaf.svg" alt="" class="img-leaf">
        <img src="@/assets/images/Image-dip-homepage.svg" alt="" class="img-dip-homepage">
      </div> -->
            <div v-if="isSubdomainEnterprise == true" class="d-flex justify-content-center w-100">
                <div class="form-login-v3">
                    <p class="title-form-v3">Sign In</p>
                    <b-form action="" @submit.prevent="tryToLogIn">
                        <div class="form-input">
                            <b-form-group>
                                <b-form-input
                                    :state="statePassword"
                                    v-model="email"
                                    type="text"
                                    class="form-control input-box-text-v3"
                                    placeholder="Email"
                                ></b-form-input>
                            </b-form-group>
                        </div>
                        <div class="form-input">
                            <b-form-group>
                                <b-form-input
                                    :state="statePassword"
                                    v-model="password"
                                    :type="passwordState"
                                    class="form-control input-box-v3"
                                    placeholder="Password"
                                ></b-form-input>
                                <b-form-invalid-feedback v-html="invalidPassword"> </b-form-invalid-feedback>
                                <img
                                    v-if="passwordState == 'password'"
                                    src="@/assets/images/icon/fi-rr-eye-close.svg"
                                    alt=""
                                    @click="showPassword()"
                                />
                                <img v-else src="@/assets/images/icon/fi-rr-eye-open.svg" alt="" @click="showPassword()" />
                            </b-form-group>
                        </div>
                        <b-form-checkbox id="checkbox-1" name="checkbox-1" value="accepted" unchecked-value="not_accepted">
                            <p class="sub-title-form-v3 margin-top-check-v3">Remember me</p>
                        </b-form-checkbox>
                        <div class="d-flex justify-content-end align-items-center">
                            <button
                                :class="loading || !email.length || !password.length ? 'btn base-btn' : 'active btn base-btn'"
                                :disabled="loading || !email.length || !password.length"
                            >
                                <i v-show="loading" class="bx bx-loader font-size-16 bx-tada"></i>
                                <span class="sub-title-form-v3"
                                    ><span :class="loading || !email.length || !password.length ? '' : 'text-white'">Sign In</span></span
                                >
                            </button>
                        </div>
                    </b-form>
                </div>
            </div>
            <div v-if="isSubdomainEnterprise == false" class="row">
                <div class="left-v3 col-md-6 d-flex justify-content-center">
                    <div class="form-login-v3">
                        <p class="title-form-v3">Sign In</p>
                        <p class="sub-title-form-v3">
                            Don’t have an account? <span @click="toSignUp()" class="cursor-pointer blue-text-v3" href="">Sign Up</span>
                        </p>
                        <b-form action="" @submit.prevent="tryToLogIn">
                            <div id="form-email-login" class="form-input">
                                <b-form-group>
                                    <b-form-input
                                        :state="statePassword"
                                        v-model="email"
                                        type="text"
                                        class="form-control input-box-text-v3"
                                        placeholder="Email"
                                    ></b-form-input>
                                </b-form-group>
                            </div>
                            <div id="form-pw-login" class="form-input">
                                <b-form-group>
                                    <b-form-input
                                        :state="statePassword"
                                        v-model="password"
                                        :type="passwordState"
                                        class="form-control input-box-v3"
                                        placeholder="Password"
                                    ></b-form-input>
                                    <b-form-invalid-feedback v-html="invalidPassword"> </b-form-invalid-feedback>
                                    <img
                                        v-if="passwordState == 'password'"
                                        src="@/assets/images/icon/fi-rr-eye-close.svg"
                                        alt=""
                                        @click="showPassword()"
                                    />
                                    <img v-else src="@/assets/images/icon/fi-rr-eye-open.svg" alt="" @click="showPassword()" />
                                </b-form-group>
                            </div>
                            <b-form-checkbox id="checkbox-1" name="checkbox-1" value="accepted" unchecked-value="not_accepted">
                                <p class="sub-title-form-v3 margin-top-check-v3">Remember me</p>
                            </b-form-checkbox>
                            <div class="d-flex justify-content-between align-items-center">
                                <span @click="toForgotPassword()" class="cursor-pointer sub-title-form-v3 blue-text-v3">Forgot Password</span>
                                <button
                                    :class="loading || !email.length || !password.length ? 'btn base-btn' : 'active btn base-btn'"
                                    :disabled="loading || !email.length || !password.length"
                                >
                                    <i v-show="loading" class="bx bx-loader font-size-16 bx-tada"></i>
                                    <span class="sub-title-form-v3"
                                        ><span :class="loading || !email.length || !password.length ? '' : 'text-white'">Sign In</span></span
                                    >
                                </button>
                            </div>
                        </b-form>
                        <div class="text-version margin-left-logo-v3">
                            <p><span style="font-family: 'Arial'">&copy;</span> 2024 {{ version }}</p>
                        </div>
                    </div>
                </div>
                <div class="right-v3 col-md-6">
                    <div class="d-flex flex-column">
                        <div class="box-title-login-v3">
                            <p class="title-login-v3">Share, access, and collaborate on files <span>securely.</span></p>
                        </div>
                        <div class="img-background">
                            <img src="@/assets/images/Image-organic-shape.svg" alt="" class="img-organic-shape" />
                            <img src="@/assets/images/Image-leaf.svg" alt="" class="img-leaf" />
                            <img src="@/assets/images/Image-dip-homepage.png" alt="" class="img-dip-homepage" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Navbar from "../../../components/nav-login-registerV3.vue";
import { authMethods } from "@/state/helpers";
import appConfig from "@/app.config";
import getNameSubDomain from "../../../utils/getNameSubDomain";

export default {
    components: {
        Navbar,
    },
    data() {
        return {
            email: "",
            password: "",
            passwordState: "password",
            loading: false,
            tryingToLogIn: false,
            invalidEmail: "Your account or Email is incorrect.",
            stateEmail: true,
            invalidPassword: "Your account or password is incorrect.",
            statePassword: true,
            authError: null,
            isAuthError: false,
            isSubdomainEnterprise: false,
            version: process.env.VUE_APP_WEB_VERSION,
        };
    },
    page: {
        title: "Sign In",
        titleTemplate: "%s - Jaybod",
        meta: [
            { name: "description", content: "Sign in to your Jaybod cloud storage account to organize, share, and collaborate on files securely." },
        ],
    },
    methods: {
        ...authMethods,
        checkSubdomain() {
            const nameSubdomain = getNameSubDomain();
            if (nameSubdomain == process.env.VUE_APP_X_DOMAIN) {
                this.isSubdomainEnterprise = false;
            } else {
                this.isSubdomainEnterprise = true;
            }
        },
        toSignUp() {
            this.$ga.event(process.env.VUE_APP_GA_LINK_TO_SIGN_UP, "Click link SignUp", "signin", 1);
            this.$router.push("/signup");
        },
        toForgotPassword() {
            this.$ga.event(process.env.VUE_APP_GA_FORGOT_PASSWORD, "Click Forgot Password", "signin", 1);
            this.$router.push("/forgot-password");
        },
        showPassword() {
            if (this.passwordState === "password") {
                this.passwordState = "text";
            } else {
                this.passwordState = "password";
            }
        },
        tryToLogIn() {
            this.$ga.event(process.env.VUE_APP_GA_BUTTON_SIGN_IN, "Click Button SignIn", "signin", 1);
            this.tryingToLogIn = true;
            sessionStorage.removeItem("invitationEmail");
            this.loading = true;
            if (this.email == "") {
                this.invalidEmail = "Please Fill Email Field.";
                this.stateEmail = false;
                this.loading = false;
                return false;
            } else {
                this.stateEmail = true;
                this.loading = false;
            }
            if (this.password == "") {
                this.invalidPassword = "Please Fill Password Field.";
                this.statePassword = false;
                this.loading = false;
                return false;
            } else {
                this.statePassword = true;
                this.loading = false;
            }
            // Reset the authError if it existed.
            this.authError = null;
            return (
                this.logIn({
                    email: this.email,
                    password: this.password,
                })
                    // eslint-disable-next-line no-unused-vars
                    .then((token) => {
                        this.tryingToLogIn = false;
                        this.isAuthError = false;
                        this.loading = false;
                        // Redirect to the originally requested page, or to the home page
                        this.$store.commit("state/SET_STATE", "storage");
                        this.$store.commit("workgroup/SET_WORKGROUP", null);
                        this.$store.commit("storage/DELETE_BREADCUMB", []);
                        this.$store.dispatch("storage/listing");
                        if (this.$route.query.hash != undefined) {
                            this.$router.push({ path: "/confirm-access-desktop", query: { ...this.$route.query } });
                        } else {
                            this.$router.push(this.$route.query.redirectFrom || { name: "home" });
                        }
                    })
                    .catch((error) => {
                        if (error.data.suspend) {
                            this.invalidEmail = "";
                            this.stateEmail = false;
                            this.tryingToLogIn = false;
                            // this.invalidPassword = error ? error : ""
                            this.invalidPassword =
                                typeof error == "object"
                                    ? `${error.display_message}. ${error.data.suspend.reason}`
                                    : "Something went wrong! Try again later.";
                            this.statePassword = false;
                            // this.authError = error ? error : "";
                            this.authError = typeof error == "object" ? "Something went wrong! Try again later." : error.data.display_message;
                            this.isAuthError = true;
                            this.loading = false;
                        } else {
                            this.invalidEmail = "";
                            this.stateEmail = false;
                            this.tryingToLogIn = false;
                            // this.invalidPassword = error ? error : ""
                            if (error.status == 422) {
                                this.invalidPassword = "Invalid email or password. Please check, then try again.";
                            } else {
                                this.invalidPassword =
                                    typeof error == "object" ? error.data.display_message : "Something went wrong! Try again later.";
                                if (
                                    error.data.display_message ==
                                    "This email is not verified yet. Check your inbox to find the verification link or resend verification email."
                                ) {
                                    localStorage.setItem("newUserEmail", this.email);
                                    this.$router.push("/verify");
                                }
                            }
                            this.statePassword = false;
                            // this.authError = error ? error : "";
                            this.authError = typeof error == "object" ? "Something went wrong! Try again later." : error.data.display_message;
                            this.isAuthError = true;
                            this.loading = false;
                        }
                    })
            );
        },
    },
    mounted() {
        this.$ga.event(process.env.VUE_APP_GA_PAGE_SIGN_IN, "Load Halaman SignIn", "signin", 1);
        if (this.$route.query.email != undefined) {
            this.email = this.$route.query.email;
        } else {
            if (sessionStorage.getItem("invitationEmail") != undefined) {
                this.email = sessionStorage.getItem("invitationEmail");
            }
        }
        this.checkSubdomain();
    },
};
</script>

<style>
.is-valid {
    border: 1px solid #ced4da !important;
    background: #ffffff;
    border-radius: 4px;
    background-image: none !important;
    padding-right: 16px !important;
}
.is-valid:focus {
    border: 1px solid #ced4da !important;
    box-shadow: none !important;
}
.invalid-feedback a {
    color: #d42020 !important;
}
#form-email-login .is-valid {
    border: 1px solid #ced4da;
    background: #ffffff;
    border-radius: 4px;
    background-image: none !important;
    padding-right: 16px !important;
}
#form-email-login .is-valid:focus {
    border: 1px solid #ced4da;
    box-shadow: none;
}
#form-pw-login .is-valid:focus {
    border: 1px solid #ced4da;
    box-shadow: none;
}
#form-pw-login .is-valid {
    border: 1px solid #ced4da;
    background: #ffffff;
    border-radius: 4px;
    background-image: none !important;
    padding-right: 16px !important;
}
#__BVID__11 div .is-valid {
    border: 1px solid #ced4da;
    background: #ffffff;
    border-radius: 4px;
    background-image: none !important;
    padding-right: 16px !important;
}
#__BVID__11 div .is-valid:focus {
    border: 1px solid #ced4da;
    box-shadow: none;
}
#__BVID__13 div .is-valid {
    border: 1px solid #ced4da;
    background: #ffffff;
    border-radius: 4px;
    background-image: none !important;
    padding-right: 16px !important;
}
#__BVID__13 div .is-valid:focus {
    border: 1px solid #ced4da;
    box-shadow: none;
}
#__BVID__74 div .is-valid {
    border: 1px solid #ced4da;
    background: #ffffff;
    border-radius: 4px;
    background-image: none !important;
    padding-right: 16px !important;
}
#__BVID__74 div .is-valid:focus {
    border: 1px solid #ced4da;
    box-shadow: none;
}

#__BVID__76 div .is-valid:focus {
    border: 1px solid #ced4da;
    box-shadow: none;
}
#__BVID__76 div .is-valid {
    border: 1px solid #ced4da;
    background: #ffffff;
    border-radius: 4px;
    background-image: none !important;
}
@media (max-width: 767px) {
    .nav-z-idx {
        height: 10vh;
    }
    .left-v3 {
        display: block;
    }
    .right-v3 {
        display: none;
    }
    .container-v3 {
        background-color: #ffffff !important;
        height: calc(100vh - 10vh);
    }
    .form-login-v3 {
        margin: 1em;
        /* margin-top: 30px; */
        /* background-color: #EFF3F5; */
    }
}
</style>
